// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  api: 'https://admin.nspace.narbutas.com/api/',
  sentryDSN: 'https://0c8e3440a2dc4904a63b48537bc49f22@o485579.ingest.sentry.io/4504042830888960',
  sentryTracesSampleRate: 0.04,
  release: '9.6.0',
  hidePrices: false,
  gofigure: '//gofigure.nspace.narbutas.com',
  xdebugKey: 'VSCODE',
  gitHash: '8fc27bf6f455bf4acd8d8aa0889f7858887045f8',
  marketingMaterialsURL: '/catalogue/category/marketing-materials/swatch-cards',
  serviceDeskURL: 'https://narbutas.atlassian.net/servicedesk/customer/portal/42/',
  imageResizeURL: 'https://imageresize.nspace.narbutas.com/_/',
};
