import { Injectable } from '@angular/core';
import { ProductsService } from '../catalogue/products/products.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { ArticleRef, ControlActionsEnum } from './model-viewer/model-viewer.component';
import {
  ArticlePropertyClassInterface,
  MigrationLogEntryPropInterface,
  ProductResponseAdditionalDataInterface,
} from '../core/models/configurator.model';

@Injectable()
export class ConfiguratorModalService {
  private processing$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private hideMigrationIssues$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentVersionId$: BehaviorSubject<number> = new BehaviorSubject(0);
  private articleVersionId$: BehaviorSubject<number> = new BehaviorSubject(0);
  private disabledState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private undoValueForAcceptedMigrationsMap$: BehaviorSubject<Map<string, MigrationLogEntryPropInterface>> = new BehaviorSubject(new Map());
  private redoValueForAcceptedMigrationsMap$: BehaviorSubject<Map<string, MigrationLogEntryPropInterface>> = new BehaviorSubject(new Map());
  private acceptedMigrationsMap$: BehaviorSubject<Map<string, MigrationLogEntryPropInterface>> = new BehaviorSubject(new Map());
  private currentPropertyValuesMap$: BehaviorSubject<Map<string, string>> = new BehaviorSubject(new Map());
  private additionalData$: BehaviorSubject<ProductResponseAdditionalDataInterface> = new BehaviorSubject(null);

  private modelPartSelectedEvent$: Subject<void> = new Subject<void>();
  private articleRef$: BehaviorSubject<ArticleRef> = new BehaviorSubject<ArticleRef>(null);
  private selectedArticleId$: Subject<string> = new Subject<string>();
  private selectedArticleProperty$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private selectedArticlePrice$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(private productsService: ProductsService) {}

  getArticleRefAsObservable(): Observable<ArticleRef> {
    return this.articleRef$.asObservable();
  }

  setArticleRef(articleRef: ArticleRef) {
    this.articleRef$.next(articleRef);
  }

  getModelPartSelectedEventAsObservable(): Observable<void> {
    return this.modelPartSelectedEvent$.asObservable();
  }

  triggerModelPartSelectedEvent(): void {
    this.modelPartSelectedEvent$.next();
  }

  getSelectedArticleIdAsObservable(): Observable<string> {
    return this.selectedArticleId$.asObservable();
  }

  setSelectedArticleId(articleId: string) {
    this.selectedArticleId$.next(articleId);
  }

  loadProduct(systemName: string, articleCode: string, variantCode?: string, orderArticle?: number) {
    return this.productsService.getProductBySystemAndArticleNumber(systemName, articleCode, variantCode, orderArticle);
  }

  processingAsObservable(): Observable<boolean> {
    return this.processing$.asObservable();
  }

  startProcessing() {
    this.processing$.next(true);
  }

  endProcessing() {
    this.processing$.next(false);
  }

  hideMigrationIssuesObservable(): Observable<boolean> {
    return this.hideMigrationIssues$.asObservable();
  }

  showAllMigrationIssues() {
    this.hideMigrationIssues$.next(false);
  }

  hideAllMigrationIssues() {
    this.hideMigrationIssues$.next(true);
  }

  currentVersionIdAsObservable(): Observable<number> {
    return this.currentVersionId$.asObservable();
  }

  setCurrentVersionId(newId: number) {
    this.currentVersionId$.next(newId);
  }

  articleVersionIdObservable(): Observable<number> {
    return this.articleVersionId$.asObservable();
  }

  setArticleVersionId(newId: number) {
    this.articleVersionId$.next(newId);
  }

  disabledStateObservable(): Observable<boolean> {
    return this.disabledState$.asObservable();
  }

  setDisabledState(newState: boolean) {
    this.disabledState$.next(newState);
  }

  selectedArticlePropertyObservable(): Observable<string> {
    return this.selectedArticleProperty$.asObservable();
  }

  setSelectedArticleProperty(id: string, property: string) {
    this.setSelectedArticleId(id);
    this.selectedArticleProperty$.next(property);
  }

  acceptedMigrationsMapObservable(): Observable<Map<string, MigrationLogEntryPropInterface>> {
    return this.acceptedMigrationsMap$.asObservable();
  }

  setAcceptedMigrationsMap(map: Map<string, MigrationLogEntryPropInterface>) {
    this.acceptedMigrationsMap$.next(map);
  }

  setAcceptedMigrationsMapOnUndoRedo(action: ControlActionsEnum): void   {
    if (action === ControlActionsEnum.UNDO) {
      const currentValue = this.acceptedMigrationsMap$.getValue();
      const undoValue = this.undoValueForAcceptedMigrationsMap$.getValue();

      this.redoValueForAcceptedMigrationsMap$.next(currentValue);
      this.acceptedMigrationsMap$.next(undoValue);

      return;
    }

    const redoValue = this.redoValueForAcceptedMigrationsMap$.getValue();
    this.acceptedMigrationsMap$.next(redoValue);
  }

  additionalDataObservable(): Observable<ProductResponseAdditionalDataInterface> {
    return this.additionalData$.asObservable();
  }

  setAdditionalData(data: ProductResponseAdditionalDataInterface) {
    this.additionalData$.next(data);
  }

  currentPropertyValuesMapObservable(): Observable<Map<string, string>> {
    return this.currentPropertyValuesMap$.asObservable();
  }

  setCurrentPropertyValuesMap(data: ArticlePropertyClassInterface[]) {
    const propertyValueToTitleMap = new Map();

    data.forEach(property => {
      property.properties.forEach(prop => {
        prop.articlePropertyValues.forEach(value => {
          propertyValueToTitleMap.set(`${prop.originalName}_${value.valueFrom}`, value.title);
        })
      })
    });

    this.currentPropertyValuesMap$.next(propertyValueToTitleMap);
  }

  setSelectedArticlePrice(price: number) {
    this.selectedArticlePrice$.next(price);
  }

  selectedArticlePriceAsObservable(): Observable<number> {
    return this.selectedArticlePrice$.asObservable();
  }

  markChangeAsAccepted(property) {
    const key = property.configuratorId + '_' + property.originalName;
    const acceptedMigrationsMap = this.acceptedMigrationsMap$.getValue();
    this.undoValueForAcceptedMigrationsMap$.next(cloneDeep(acceptedMigrationsMap));

    if (acceptedMigrationsMap.has(key)) {
      acceptedMigrationsMap.delete(key);
    } else {
      acceptedMigrationsMap.set(key, property);
    }

    this.setAcceptedMigrationsMap(acceptedMigrationsMap);
  }
}
