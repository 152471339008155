import { OrderArticlesListColumns } from '../order-articles-list.interface';

export const COLUMNS: OrderArticlesListColumns = {
  selectBox: { prop: 'selectBox', name: null, visible: true, width: 3 },
  item: { prop: 'item', name: 'ORDER_ARTICLES.LIST.COLUMN.ITEM', visible: true, width: 0 },
  materialsAndType: {
    prop: 'materialsAndType',
    name: 'ORDER_ARTICLES.LIST.COLUMN.MATERIALS',
    secondName: 'ORDER_ARTICLES.LIST.COLUMN.TYPE',
    visible: true,
    width: 12.25,
  },
  details: { prop: 'size', name: 'ORDER_ARTICLES.LIST.COLUMN.DETAILS', visible: true, width: 10.5 },
  quantity: { prop: 'quantity', name: 'ORDER_ARTICLES.LIST.COLUMN.QTY', visible: true, width: 3.5 },
  price: { prop: 'price', name: 'ORDER_ARTICLES.LIST.COLUMN.SINGLE_PRICE', visible: true, width: 6.5 },
  totalPrice: { prop: 'totalPrice', name: 'ORDER_ARTICLES.LIST.COLUMN.TOTAL_PRICE', visible: true, width: 6.5 },
  netPrice: {
    prop: 'netPrice',
    name: 'ORDER_ARTICLES.LIST.COLUMN.NET_PRICE',
    visible: false,
    width: 6.5,
  },
  actions: { prop: 'actions', name: null, visible: true, width: 4 },
};
