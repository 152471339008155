import { Injectable } from '@angular/core';
import { OrderUIStatus } from '../core/enums/order-status.enum';
import { GroupedDealerRowsInterface, GroupedPMRowsInterface, OrderInterface, OrderListRowInterface, RowDealerInterface } from '../core/models/order.model';
import { UserRole } from '../core/enums/user-role.enum';
import { UserService } from '../core/services/user/user.service';
import { UserInterface } from '../core/models/user.model';
import { FilterableListHelperServiceInterface } from "../filterable-list/filterable-list-helper-service.interface";
import { OrderImportState, OrderMigrationState, OrderState } from "../core/enums/order.state.enum";
import { OrdersService } from './orders.service';

@Injectable()
export class OrdersHelperService implements FilterableListHelperServiceInterface {
  user: UserInterface;

  constructor(
    private userService: UserService,
    private ordersService: OrdersService,
  ) {
    userService.fromStorage().subscribe(user => {
      this.user = user;
    });
  }

  generateRows(items: OrderInterface[]): OrderListRowInterface[] {
    return items.reduce((carry, order) => {
      const {
        id,
        price,
        title,
        updatedAtTimestamp: date,
        state,
        importState,
        migrationState,
        messagesCount,
        companyName,
        axClientCode,
        sentToAxDate: axDate,
        versionOutdated,
        isSendingToAX,
        referenceCode,
        updatedAt,
        volume,
        weight,
        axOrderNum,
        shippingAddress,
        axDispatchDate,
        inaccurateWeightOrVolume,
        priceList
      } = order;
      const dealer: RowDealerInterface = { companyName, axClientCode, };
      carry.push({
        id,
        price: { price, currency: priceList?.currency?.name },
        title,
        date: date * 1000,
        companyName,
        state,
        markStatus: this.markForUI(state, importState, migrationState, versionOutdated),
        dealer,
        messagesCount: messagesCount || 0,
        axDate: +axDate * 1000,
        versionOutdated,
        isSendingToAX,
        referenceCode,
        updatedAt,
        volume,
        weight,
        axOrderNum,
        shippingAddress,
        axDispatchDate: +axDispatchDate * 1000,
        inaccurateWeightOrVolume
      } as OrderListRowInterface);
      return carry;
    }, [] as OrderListRowInterface[]);
  }

  markForUI(state: OrderState, importState: OrderImportState, migrationState: OrderMigrationState, versionOutdated = false): number {
    if (versionOutdated && (state === OrderState.DRAFT || state === OrderState.WAITING)) {
      return OrderUIStatus.PENDING;
    }

    if (OrderImportState.IMPORTING === importState) {
      return OrderUIStatus.IMPORTING;
    }

    if (OrderMigrationState.MIGRATING === migrationState) {
      return OrderUIStatus.MIGRATING;
    }

    switch (state) {
      case OrderState.DRAFT:
        return OrderUIStatus.DEFAULT;
      case OrderState.WAITING:
      case OrderState.IN_AX:
        return OrderUIStatus.PENDING;
      case OrderState.AX_LOADED:
        return OrderUIStatus.DONE;
      case OrderState.DELETED:
        return OrderUIStatus.REMOVED;
      case OrderState.AX_ARCHIVED:
      case OrderState.AX_ARCHIVE_CONFIRMED:
        return OrderUIStatus.ARCHIVED;
    }
  }

  /**
   * Group rows to offers, orders, archives, trashes
   * @param rows
   */
  groupRowsByState(rows: any[]): GroupedDealerRowsInterface | GroupedPMRowsInterface {
    switch (this.user.role.name) {
      case UserRole.ROLE_DEALER:
        return rows.reduce(
          (acc, order: OrderInterface) => {
            switch (order.state) {
              case OrderState.DRAFT:
                acc.offers.push(order);
                break;
              case OrderState.WAITING:
              case OrderState.IN_AX:
                acc.pending.push(order);
                break;
              case OrderState.AX_CONFIRMED:
                acc.confirmed.push(order);
                break;
              case OrderState.AX_LOADED:
                acc.loaded.push(order);
                break;
              case OrderState.AX_ARCHIVED:
              case OrderState.AX_ARCHIVE_CONFIRMED:
                acc.archives.push(order);
                break;
              case OrderState.DELETED:
                acc.trashes.push(order);
                break;
            }
            return acc;
          },
          { offers: [], archives: [], trashes: [], confirmed: [], pending: [], loaded: [] }
        );
      case UserRole.ROLE_PM:
      case UserRole.ROLE_PM_RU:
        return rows.reduce(
          (acc, order: OrderInterface) => {
            switch (order.state) {
              case OrderState.WAITING:
                acc.waiting.push(order);
                break;
              case OrderState.IN_AX:
                acc.pending.push(order);
                break;
              case OrderState.AX_CONFIRMED:
                acc.confirmed.push(order);
                break;
              case OrderState.AX_LOADED:
                acc.loaded.push(order);
                break;
              case OrderState.AX_ARCHIVED:
              case OrderState.AX_ARCHIVE_CONFIRMED:
                acc.archives.push(order);
                break;
            }
            return acc;
          },
          { waiting: [], pending: [], confirmed: [], loaded: [], archives: [] }
        );
      case UserRole.ROLE_PM_NARBUTAS:
        return rows.reduce(
          (acc, order: OrderInterface) => {
            switch (order.state) {
              case OrderState.DRAFT:
              case OrderState.WAITING:
                acc.orders.push(order);
                break;
              default:
                acc.confirmed.push(order);
                break;
            }
            return acc;
          },
          { orders: [], confirmed: [] }
        );
    }
  }

  downloadProformaFile(order: OrderInterface) {
    window.location.href = order.proformaFile;
  }

  onExportOrderToPdf(order: OrderListRowInterface) {
    window.dataLayer.push({
      event: 'saveOrderToPdf',
      order: order.id,
    });

    this.ordersService.exportToPdf(order.id, order.title);
  }
}
