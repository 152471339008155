import { OrderArticleSaleCoefficientType, OrderArticleSaleDiscountType } from '../enums/order-article-discount-type.enum';
import { PageBreakInterface } from './page-break.model';
import { OrderArticleMaterialInterface } from '../../ui-elements/order-article-material-input/order-article-material-interface';
import { PriceRequestItemInterface } from './price-request-item.model';

export interface FormattedPricesInterface {
  formattedConfigurationDiscount?: string; // configuration discount (including children discounts) = (100 - (100 * formattedConfigurationPrice / formattedTotalConfigurationPrice))
  formattedConfigurationPrice?: string; // configuration price (including children configuration prices) with discount already applied
  formattedConfigurationPurchasePrice?: string; // single configuration pricelist price (including children configuration pricelist prices) without discount
  formattedConfigurationSalePrice?: string; // configuration sale price (including children configuration sale prices) without discount applied

  formattedTotalConfigurationPrice?: string; // formattedConfigurationPrice * quantity
  formattedTotalConfigurationPurchasePrice?: string; // formattedConfigurationPurchasePrice * quantity
  formattedTotalConfigurationSalePrice?: string; // formattedConfigurationSalePrice * quantity

  formattedDiscount?: string; // purchase discount rounded to 1 decimal
  formattedQuantityDiscount?: number; // purchase quantity discount rounded to 1 decimal
  formattedSaleDiscount?: string; // sale discount (dealer applied discount)

  formattedPrice?: string; // price rounded to 2 decimals
  formattedPurchasePrice?: string; // customPrice|pricelistPrice|axPrice rounded to 2 decimals
  formattedSalePrice?: string; // sale price

  formattedTotalPrice?: string; // formattedPrice * quantity rounded to 2 decimals with discount applied
  formattedTotalPurchasePrice?: string; // formattedPurchasePrice * quantity rounded to 2 decimals (without discount applied)
  formattedTotalSalePrice?: string; // formattedSalePrice * quantity rounded to 2 decimals (without discount applied)

  formattedSaleCoefficient: string;
  formattedConfigurationDefaultSaleCoefficient?: string;
}

export type OrderArticleInterface = FormattedPricesInterface & {
  id: number;
  position: number;
  type?: OrderArticleType | null;

  code: string;
  fullCode: string;

  discount: number;
  discountType: number;

  saleDiscount: number;
  saleDiscountType?: OrderArticleSaleDiscountType;

  quantityDiscount?: number;

  saleCoefficient: number;
  saleCoefficientType: OrderArticleSaleCoefficientType;

  price: number;
  purchasePrice?: number;
  salePrice?: number;
  customPrice?: number;
  pricelistPrice?: number;

  totalSalePrice?: number;
  totalPrice: number;

  configurationQuantity: number;
  orderQuantity?: number;
  originalQuantity?: number;

  quantity: number;

  children?: OrderArticleInterface[];

  category?: string;
  system?: string;

  volume?: string | null;
  weight?: number;

  width?: number;
  depth?: number;
  height?: string;
  dimensions: string;

  title?: string;
  shortText?: string;
  longText?: string;
  details: string;
  typeInformation?: string;
  translations?: OrderArticleTranslationInterface[];

  img?: string;
  imageGenerated?: boolean;

  orderArticleMaterials?: OrderArticleMaterialInterface[];
  orderArticleMaterialsWithChildren: OrderArticleMaterialInterface[];

  version?: OrderArticleVersionInterface;
  versionOutdated: boolean;
  orderArticleMigration?: OrderArticleMigrationInterface;

  pageBreak?: PageBreakInterface;
  customMadePriceRequestItem: PriceRequestItemInterface | null;

  createdAtTimestamp?: number;
  updatedAtTimestamp?: number;
  articleCategoryType?: number;
  serie?: string;
  configurationDiscount?: number;
  configurationPrice?: number;
  configurationPurchasePrice?: number;
  defaultSalePrice?: number;
  configurationSalePrice?: number;
  totalConfigurationSalePrice?: number;
  belongsToLockedGroup?: boolean;
};

export interface OrderArticleTranslationInterface {
  locale: string;
  content: string;
}

export interface OrderArticleMigrationInterface {
  id?: number;
  status?: OrderArticleMigrationStatusType;
  version?: OrderArticleVersionInterface;
}

export interface OrderArticleVersionInterface {
  id?: number;
}

export interface OrderArticleCreateInterface {
  itemId: string;
  quantity: number;
  order: number;
  pageBreak?: number;
  saleDiscountType?: OrderArticleSaleDiscountType;
  saleDiscount?: number;
  saleCoefficient?: number;
}

export interface OrderArticleUpdateInterface {
  itemId?: string;
  itemType?: number;
  quantity?: number;
  order?: number;
  pageBreak?: number;
  orderArticles?: number[];
  saleDiscountType?: OrderArticleSaleDiscountType;
  saleDiscount?: number;
  saleCoefficient?: number;
  saleCoefficientType?: OrderArticleSaleCoefficientType;
  customPrice?: number;
  pricelistPrice?: number;
}

export interface OrderArticleUpdatePositionInterface {
  id: number;
  position: number;
  pageBreak: number;
}

export interface BatchOrderArticlesInterface {
  orderArticles: OrderArticleInterface[];
  discount: number;
  price: number;
  discountedPrice: number;
  vat: number;
  vatPercentage: number;
  priceWithVatApplied: number;
}

export interface OrderArticlesBatchUpdateInterface {
  orderArticles: OrderArticleBatchUpdateInterface[];
}

export interface OrderArticleBatchUpdateInterface {
  discount?: number;
  price?: number;
  customPrice?: number|undefined;
  saleDiscount?: number;
  saleCoefficient?: number;
  itemType?: number;
  quantity?: number;
  order?: number;
  pageBreak?: number;
  discountType?: ORDER_ARTICLE_DISCOUNT_TYPE;
  discountedPrice?: number | string;
  saleCoefficientType?: OrderArticleSaleCoefficientType;
  salePrice?: number | string;
}

export interface OtherSupplierOrderArticleInterface {
  order?: number;
  longText?: string;
  quantity?: number;
  code?: string;
  shortText?: string;
  system?: string;
  fullCode?: string;
  category?: string;
  details?: string;
  title?: string;
  price?: number;
  formattedTotalConfigurationPrice?: string;
  img?: File | string;
  typeInformation?: string;
  id?: number;
  type?: number;
  customPrice?: number;
  purchasePrice?: number;
  pricelistPrice?: number;
  saleDiscount?: number;
  saleCoefficient?: number;
  salePrice?: number;
  itemType?: number;
  discount?: number;
  // it's either a number if item is not yet created
  // or a PageBreak object if item already exists
  pageBreak?: number | PageBreakInterface;
  position?: number;
  volume?: string | null;
  weight?: number;
  width?: number;
  height?: string;
  depth?: number;
}

export interface OtherSupplierOrderArticleCreateInterface {
  order?: number;
  longText?: string;
  quantity?: number;
  shortText?: string;
  system?: string;
  fullCode?: string;
  code?: string;
  category?: string;
  details?: string;
  title?: string;
  img?: File;
  typeInformation?: string;
  position?: number;
  volume?: string | null;
  weight?: number;
  width?: number;
  height?: string;
  depth?: number;
}

export enum ORDER_ARTICLE_DISCOUNT_TYPE {
  CUSTOM = 10,
  QUANTITY = 20,
}

export enum OrderArticleType {
  OTHER_SUPPLIER_ITEM = 60,
  PM_NARBUTAS_CUSTOM_ITEM_TYPE = 40,
  CUSTOM_ITEM_TYPE = 40,
  ADDITIONAL_ITEM = 50,
  PRICE_REQUEST_ITEM = 70,
}

export enum OrderArticleFormItemType {
  STANDARD = 0,
  OTHER_SUPPLIER_ITEM = 1,
  CUSTOM_NON_STANDARD_ITEM = 2,
}

export enum OrderArticleMigrationStatusType {
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export function orderArticleTypeToFormValue(type: OrderArticleType)  {
  switch (type) {
    case OrderArticleType.OTHER_SUPPLIER_ITEM:
      return OrderArticleFormItemType.OTHER_SUPPLIER_ITEM;
    case OrderArticleType.CUSTOM_ITEM_TYPE:
      return OrderArticleFormItemType.CUSTOM_NON_STANDARD_ITEM;
    default:
      return OrderArticleFormItemType.STANDARD;
  }
}
