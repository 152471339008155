<app-loader #loader [cssClasses]="'loader--white'"></app-loader>
<div
  class="viewer-component justify-content-center align-items-center d-flex viewer-component--missing-view"
  *ngIf="viewerNotAvailable && !loader.loading"
>
  <svg>
    <use xlink:href="#icon-3d-box"/>
  </svg>
</div>

<div class="viewer-component" #viewer appBorderBoxResizeObserver (onSizeChange)="viewerContainerSizeChanged()"></div>

<div class="viewer-controls mt-4 mb-3">
  <div
    *ngIf="!loader.loading"
    class="d-flex justify-content-center gap-3"
  >
    <div
      class="d-inline-block ms-3"
      ngbDropdown
      placement="top-start"
      #downloadDropdown="ngbDropdown"
    >
      <button
        ngbDropdownToggle
        class="btn hover-bg-gray-200 hover-color-black p-1 d-flex no-outline"
        [disabled]="viewerNotAvailable"
        [ngClass]="{ 'bg-gray-400 color-black': downloadDropdown.isOpen() }"
      >
        <i class="icon ni-download d-bock"></i>
      </button>

      <div ngbDropdownMenu class="shadow">
        <div class="border-bottom">
          <button class="btn btn-default d-flex align-items-center px-3 py-2
                         bg-gray-200 color-gray-600 hover-color-black hover-bg-gray-100"
                  ngbDropdownItem
                  (click)="downloadFile(fileFormat.JPG, 600, 800)"
          >
            <i class="icon ni-download my-1"></i> {{ 'CONFIGURATOR_MODAL.DOWNLOAD.IMAGE' | translate: {dimension: '800x600'} }}
          </button>
        </div>
        <div class="border-bottom">
          <button class="btn btn-default d-flex align-items-center px-3 py-2
                         bg-gray-200 color-gray-600 hover-color-black hover-bg-gray-100"
                  ngbDropdownItem
                  (click)="downloadFile(fileFormat.JPG, 1080, 1920)"
          >
            <i class="icon ni-download my-1"></i> {{ 'CONFIGURATOR_MODAL.DOWNLOAD.IMAGE' | translate: {dimension: '1920x1080'} }}
          </button>
        </div>
        <button class="btn btn-default d-flex align-items-center px-3 py-2
                       bg-gray-200 color-gray-600 hover-color-black hover-bg-gray-100"
                ngbDropdownItem
                (click)="downloadFile(fileFormat.DWG)"
        >
          <i class="icon ni-download my-1"></i> {{ 'CONFIGURATOR_MODAL.DOWNLOAD.CAD_DATA' | translate }}
        </button>
      </div>
    </div>

    <div class="vr"></div>

    <button
      class="btn hover-bg-gray-200 hover-color-black p-1 d-flex no-outline"
      title="{{ 'CONFIGURATOR_MODAL.SWITCH_BG_MODE' | translate }}"
      (click)="onSetBackground(activeBgMode === lightMode ? darkMode : lightMode)"
    >
      <i class="icon ni-flaky"></i>
    </button>

    <div class="vr"></div>

    <button
      class="btn p-1 d-flex no-outline"
      [disabled]="!undoPossible"
      [ngClass]="{'hover-bg-gray-200 hover-color-black': undoPossible}"
      (click)="triggerAction(controlActions.UNDO)"
      data-testid="undoButton"
    >
      <i class="icon ni-undo"></i>
    </button>

    <button
      class="btn hover-bg-gray-200 hover-color-black p-1 d-flex no-outline"
      [disabled]="!redoPossible"
      [ngClass]="{'hover-bg-gray-200 hover-color-black': redoPossible}"
      (click)="triggerAction(controlActions.REDO)"
      data-testid="redoButton"
    >
      <i class="icon ni-redo d-bock"></i>
    </button>
  </div>
</div>
