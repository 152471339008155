import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, zip } from 'rxjs';
import { filter, finalize, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import FeatureFlags from '../../../featureFlags.json';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ProductArticleVariant } from '../catalogue/products/product.model';
import { ProductsService } from '../catalogue/products/products.service';
import { ConfiguratorModalActionType } from '../core/enums/configurator-modal-action-type.enum';
import { OrderState } from "../core/enums/order.state.enum";
import { OrderListRouteType, RouteTypeEnums } from '../core/enums/route-types.enum';
import { SelectionMenuEntityEnum } from '../core/enums/selection-menu-entity.enum';
import { UserRole } from '../core/enums/user-role.enum';
import {
  ArticlePropertyClassPropertyInterface,
  MigrationChangeInterface,
  ProductResponseAdditionalDataInterface,
  ProductResponseInterface,
  TransformedResponseInterface,
} from '../core/models/configurator.model';
import { ORDER_ARTICLE_DISCOUNT_TYPE, OrderArticleCreateInterface, OrderArticleInterface } from '../core/models/order-article.model';
import { OrderInterface } from '../core/models/order.model';
import { PriceRequestInterface } from '../core/models/price-request.model';
import { UserInterface } from '../core/models/user.model';
import { CataloguePathInterface } from '../core/services/catalogue-path/catalogue-path.service';
import { OrderArticleService } from '../core/services/order-article/order-article.service';
import { UserService } from '../core/services/user/user.service';
import { NavbarElements } from '../core/tree/navbar-elements';
import { ERROR_STATUSES } from '../header-message/server-errors.interceptor';
import { OrderArticlesListRowInterface } from '../orders/order-articles-list/order-articles-list.interface';
import { OrdersService } from '../orders/orders.service';
import { OrderSelectOnChangeEventInterface, SelectionMenuComponent, TabTypes } from '../selection-menu/selection-menu.component';
import { SelectionMenuService } from '../selection-menu/selection-menu.service';
import { SharedModule } from '../shared/shared.module';
import { GenericModalTypes } from '../ui-elements/generic-modal/generic-modal-types';
import {
  OkCancelOptionalControlModalComponent
} from '../ui-elements/generic-modal/ok-cancel-optional-control-modal/ok-cancel-optional-control-modal.component';
import { LoaderComponent } from '../ui-elements/loader/loader.component';
import { LoaderService } from '../ui-elements/loader/loader.service';
import { ToastService } from '../ui-elements/toast/toast.service';
import { InquiriesRoutePath, OrderRoutePath } from './../core/enums/route-types.enum';
import { ActionType } from './action-type.enum';
import { ArticleSelectorComponent } from './article-selector/article-selector.component';
import { ConfigurationArticle } from './configuration-article.model';
import { ConfiguratorModalService } from './configurator-modal.service';
import { FooterComponent, FooterOnAddToPriceRequestEventInterface, FooterOnOrderEventInterface } from './footer/footer.component';
import MeshExtended from './mesh/mesh-extended';
import { MigrationsSidebarModalComponent } from './migrations-sidebar-modal/migrations-sidebar-modal.component';
import { ArticleRef, ControlActionsEnum, ModelViewerComponent } from './model-viewer/model-viewer.component';
import {
  PropertiesConfiguratorComponentFactoryService
} from './properties-configurator-component-factory/properties-configurator-component-factory.service';
import { GenericPropertyComponent } from './properties-configurator-property-classes/default-property-classes/generic-property/generic-property.component';
import { PropertyClassGeneratorService } from './property-class-generator.service';
import { PropertyClassesBuilder } from './property-classes-builder';
import { PropertyValueChangeEventInterface } from './property-values-selector/property-values-selector.component';
import { SessionService } from './session/session.service';
import { UpdateService } from '../update.service';

export interface ConfiguratorModalOnSaveEventInterface {
  order?: OrderInterface;
  orderArticle: OrderArticleInterface;
}

export interface ConfiguratorModalOnModifyDiscountsEventInterface {
  ids: number[];
}

@Component({
    selector: 'app-configurator-modal',
    templateUrl: './configurator-modal.component.html',
    styleUrls: ['./configurator-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ProductsService, OrdersService],
    imports: [SharedModule, LoaderComponent, ModelViewerComponent, ArticleSelectorComponent, GenericPropertyComponent, FooterComponent]
})
export class ConfiguratorModalComponent implements OnInit, OnDestroy {
  @ViewChild('loaderOfPropertiesConfiguration', { static: true }) loaderOfPropertiesConfiguration: LoaderComponent;
  @ViewChild('loaderOfWholeModal', { static: true }) loaderOfWholeModal: LoaderComponent;
  @ViewChild('propertiesConfigurator', { read: ViewContainerRef, static: true }) propertiesConfigurator: ViewContainerRef;
  @ViewChild('componentConfigurationsInner', { read: ElementRef, static: true }) componentConfigurationsInner: ElementRef;
  @ViewChild('toastAfterUpdate') private toastUpdateRef: TemplateRef<any>;

  @Input() article;
  @Input() system: string;
  @Input() articleVariant: ProductArticleVariant;
  @Input() order?: OrderInterface;
  @Input() orderArticleId?: number;
  @Input() action? = this.userService.isDealer()
          ? ConfiguratorModalActionType.ADD_TO_OFFER
          : ConfiguratorModalActionType.ADD_TO_ORDER;
  @Input() group?: any;
  @Input() priceRequest?: PriceRequestInterface | null;
  @Input() quantity? = 1;
  @Input() articleRow: OrderArticlesListRowInterface;

  @Output() save: EventEmitter<ConfiguratorModalOnSaveEventInterface> = new EventEmitter<ConfiguratorModalOnSaveEventInterface>();
  @Output() modifyDiscounts: EventEmitter<ConfiguratorModalOnModifyDiscountsEventInterface> = new EventEmitter<
    ConfiguratorModalOnModifyDiscountsEventInterface
  >();
  @Output() error: EventEmitter<ActionType> = new EventEmitter<ActionType>();

  public showMigrationIssues = false;
  public hasMigrationIssues = 0;
  public closingMigrationIssues = false;
  public count = 0;
  public isDisabled = false;
  public transformedResponse: TransformedResponseInterface;
  public currentArticleId = '';
  public currentArticlePrice = 0;
  public isPmNarbutas = this.userService.isPmNarbutas();

  public additional: ProductResponseAdditionalDataInterface = {
    configuratorUrl: null,
    sessionId: null,
    rootId: null,
    sessionConfiguration: null,
  };

  public configurationArticles: ConfigurationArticle[] = [];
  public configurationArticlesWithMigration: string[] = [];
  public migrationLog: MigrationChangeInterface[] = [];

  /**
   * Indicator, that indicates if 3D view should be re-rendered
   */
  public updated?: number = null;
  public cataloguePath: CataloguePathInterface;
  private currentArticleVersion = 0;
  private skipVersionChangeNotification = false;
  private subscriptions: Subscription = new Subscription();
  public orderArticle: OrderArticleInterface;

  private propertiesConfiguratorComponentRef: ComponentRef<any>;
  public toastContent = '';
  public toastButtonText = '';
  public toastUrl = '';
  public selectionMenuEntity: SelectionMenuEntityEnum | null;
  public articleLoadCompleted = false;
  public failedToLoad = false;

  public selectionMenuEntities = SelectionMenuEntityEnum;
  public nameToOriginalNameMap: Map<string, string> = new Map();
  public configurationLoaded = false;
  public orderArticleChanged = false;
  public skipNextSelectedArticleIdValue = false
  public setSelectedArticleIdToNull = false
  private articleRef: ArticleRef;
  public lastUsedProperty: string = null;
  private lastScrollPosition: number = null;
  private shouldScrollToRelativeMigration = false;
  public controlActionInProgress = false;

  FeatureFlags = FeatureFlags;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      event.preventDefault();

      if (this.transformedResponse?.additional?.undoPossible) {
        this.onControlAction(ControlActionsEnum.UNDO);
      }
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
      event.preventDefault();

      if (this.transformedResponse?.additional?.redoPossible) {
        this.onControlAction(ControlActionsEnum.REDO);
      }
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private translator: TranslateService,
    private configuratorModalService: ConfiguratorModalService,
    private ordersService: OrdersService,
    private userService: UserService,
    private productsService: ProductsService,
    private orderArticleService: OrderArticleService,
    private selectionMenuService: SelectionMenuService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private sessionService: SessionService,
    private router: Router,
    private propertiesConfiguratorComponentFactory: PropertiesConfiguratorComponentFactoryService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private updateService: UpdateService,
  ) { }

  ngOnInit() {
    this.updateService.setUpdateDetectionPaused(true);
    
    if (this.order) {
      this.selectionMenuService.openOrder(this.order);
    }

    this.configuratorModalService.setDisabledState(false);

    if (this.order) {
      this.selectionMenuService.selectOrder(this.order);
      this.selectionMenuService.selectGroups(this.group);
    } else if (this.priceRequest) {
      this.selectionMenuService.selectPriceRequest(this.priceRequest);
    }

    this.subscriptions.add(
      this.selectionMenuService.selectionMenuEntityAsObservable().subscribe((entity) => {
        this.selectionMenuEntity = entity;
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.disabledStateObservable().subscribe(state => {
        this.isDisabled = state;
        this.loaderOfPropertiesConfiguration.visible(state);

        if (this.skipVersionChangeNotification === true || !this.isDisabled) {

          return;
        }

        this.translator.get('CONFIGURATOR_MODAL.ORDER.DISABLED_BY_VERSION_UPDATE').subscribe(translation => {
          this.toastService.danger(translation, {
            autohide: false,
            afterClose: () => {
              this.skipVersionChangeNotification = true;
            }
          });
        });
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.currentVersionIdAsObservable().subscribe(res => {
        if (!res) {

          return;
        }

        // checking for version changes in simple catalog article
        if (!this.orderArticle && this.currentArticleVersion !== 0 && this.currentArticleVersion !== res) {
          this.configuratorModalService.setDisabledState(true);
        }

        if (this.orderArticle) {
          // skip this if orderArticle is already outdated/in migration
          if (this.orderArticle.versionOutdated) {

            return;
          }
          // checking for version changes while previewing order article
          if (this.orderArticle.version && this.orderArticle.version.id !== res) {
            if (this.orderArticle.orderArticleMigration && this.orderArticle.orderArticleMigration.version.id === res) {

              return;
            }

            this.configuratorModalService.setDisabledState(true);
          }
        }
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.getSelectedArticleIdAsObservable().subscribe(nextId => {
        if (this.skipNextSelectedArticleIdValue) {
          this.skipNextSelectedArticleIdValue = false;
          this.ngDetectChanges();

          return;
        }

        this.currentArticleId = nextId;
        this.onArticleSelect(nextId)
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.getModelPartSelectedEventAsObservable().subscribe(() => {
        this.componentConfigurationsInner.nativeElement.scrollTop = 0;
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.getArticleRefAsObservable().subscribe(articleRef => {
        this.articleRef = articleRef;

        if (this.orderArticleChanged) {
          this.configuratorModalService.setSelectedArticleId(this.setSelectedArticleIdToNull ? null : this.transformedResponse?.configuratorId);
          this.orderArticleChanged = false;
          this.setSelectedArticleIdToNull = false
        }
      })
    );

    this.subscriptions.add(
      this.configuratorModalService.selectedArticlePriceAsObservable().subscribe(nextPrice => {
        this.currentArticlePrice = nextPrice;
      })
    );

    if (this.orderArticleId) {
      this.orderArticleService
        .getOne(this.orderArticleId)
        .noCache()
        .subscribe(orderArticle => {
          this.orderArticle = orderArticle as OrderArticleInterface;
          this.prepareConfigurator();
        });

      return;
    }

    this.prepareConfigurator();
  }

  ngOnDestroy() {
    if (this.isDisabled) {
      this.configuratorModalService.setDisabledState(false);
    }

    this.updateService.setUpdateDetectionPaused(false);
    this.skipVersionChangeNotification = false;
    this.subscriptions.unsubscribe();
  }

  prepareConfigurator() {
    this.subscriptions.add(
      this.userService
        .fromStorage()
        .pipe(
          tap((user: UserInterface) => {
            if (([UserRole.ROLE_PM_NARBUTAS, UserRole.ROLE_PM] as string[]).includes(user.role.name) && this.orderArticle) {
              const hasCustomDiscount =
                (this.orderArticle.discountType === ORDER_ARTICLE_DISCOUNT_TYPE.CUSTOM && this.orderArticle.discount > 0) ||
                this.orderArticle.children.some((child: OrderArticleInterface) => {
                  return child.discountType === ORDER_ARTICLE_DISCOUNT_TYPE.CUSTOM && child.discount > 0;
                });

              if (hasCustomDiscount) {
                this.translateService
                  .get([
                    'CONFIGURATOR_MODAL.CUSTOM_PRICE_WARNING.BUTTON.CANCEL',
                    'CONFIGURATOR_MODAL.CUSTOM_PRICE_WARNING.BUTTON.OK',
                    'CONFIGURATOR_MODAL.CUSTOM_PRICE_WARNING.BUTTON.MODIFY_DISCOUNTS',
                    'CONFIGURATOR_MODAL.CUSTOM_PRICE_WARNING.HEADER',
                    'CONFIGURATOR_MODAL.CUSTOM_PRICE_WARNING.BODY',
                  ])
                  .subscribe((translations: any) => {
                    const [cancel, ok, optionalControl, headerContent, bodyContent] = Object.values(translations);
                    const modalRef = this.modalService.open(OkCancelOptionalControlModalComponent, {
                      windowClass: GenericModalTypes.ORANGE,
                      size: 'lg',
                      keyboard: false,
                      backdrop: 'static',
                    });

                    if (([UserRole.ROLE_PM_NARBUTAS] as string[]).includes(user.role.name)) {
                      modalRef.componentInstance.optionalControl = {
                        text: optionalControl,
                        classes: 'btn--with-icon btn--with-icon--percentage',
                      };

                      modalRef.componentInstance.optionalControlClick.subscribe(() => {
                        this.activeModal.close();
                        this.modifyDiscounts.emit({
                          ids: [this.orderArticle.id],
                        });
                      });
                    }

                    modalRef.componentInstance.buttonTexts = [cancel, ok];
                    modalRef.componentInstance.bodyContent = bodyContent;
                    modalRef.componentInstance.headerContent = headerContent;

                    modalRef.componentInstance.cancel.subscribe(() => {
                      this.activeModal.close();
                    });

                    modalRef.componentInstance.ok.subscribe(() => { });
                  });
              }
            }
          }),
          switchMap(() => {
            this.loadArticle(this.system, this.article, this.articleVariant, this.orderArticle);

            return this.configuratorModalService.processingAsObservable();
          })
        )
        .subscribe((processing: boolean) => {
          this.loaderOfPropertiesConfiguration.visible(processing);
        })
    );
  }

  /**
   * Catches property class change, updates and reloads article
   *
   * @param {string} propClass
   * @param {string} propertyName
   * @param {string} valueFrom
   * @param {string} configuratorId
   */
  onPropertyClassChange(
    { propertyClass, propertyName, valueFrom, configuratorId, orderArticle }: PropertyValueChangeEventInterface,
    controllingPropertyIndex?: number,
  ) {
    this.configuratorModalService.startProcessing();

    let controllingProperty: ArticlePropertyClassPropertyInterface = null;
    let localConfiguratorId = configuratorId;

    this.productsService.updateProductProperty(configuratorId, propertyClass, propertyName, valueFrom, orderArticle).subscribe({
      next: (data: ProductResponseInterface) => {
        this.updateInternals(data);

        if (controllingPropertyIndex !== undefined) {
          controllingProperty = this.transformedResponse?.controllingProperties[controllingPropertyIndex];
          localConfiguratorId = this.transformedResponse.configuratorId;
        }

        this.productsService.getConfigurationArticles().subscribe(res => {
          this.configurationArticles = res;

          if (controllingProperty) {
            this.configuratorModalService.setSelectedArticleId(localConfiguratorId);
          } else {
            // indicate that we need to set selected article later
            this.orderArticleChanged = true;
          }

          this.configuratorModalService.endProcessing();
        });
      },
      error: (error) => {
        if (error instanceof HttpErrorResponse && error.status === ERROR_STATUSES.BAD_REQUEST) {
          this.error.emit(ActionType.UPDATE);
          this.configuratorModalService.endProcessing();
        }
      },
      complete: () => {
        this.ngDetectChanges();
      }
    });
  }

  onControlAction(action: ControlActionsEnum): void {
    if (this.controlActionInProgress) {
      return;
    }

    this.controlActionInProgress = true;
    this.configuratorModalService.startProcessing();

    const actionObservable = action === ControlActionsEnum.UNDO ? this.productsService.undo() : this.productsService.redo();

    actionObservable.pipe(
      switchMap((data: ProductResponseInterface) => {
        this.updateInternals(data);
        return this.productsService.getConfigurationArticles()
      }),
      finalize(() => {
        this.controlActionInProgress = false;
        this.configuratorModalService.endProcessing();
      })
    ).subscribe({
      next: (articles) => {
        this.configurationArticles = articles;
        this.currentArticleId = this.transformedResponse?.configuratorId;

        this.setSelectedArticleIdToNull = true
        this.skipNextSelectedArticleIdValue = true
        this.orderArticleChanged = true;
        this.ngDetectChanges();

        this.configuratorModalService.setAcceptedMigrationsMapOnUndoRedo(action);
      },
      error: (error) => {
        if (error instanceof HttpErrorResponse && error.status === ERROR_STATUSES.BAD_REQUEST) {
          this.error.emit(ActionType.UPDATE);
        }
      }
    })
  }

  /**
   * Loads article
   *
   * @param {string} system
   * @param article
   * @param articleVariant
   * @param {OrderArticleInterface} orderArticle
   */
  loadArticle(system: string, article?, articleVariant?: ProductArticleVariant, orderArticle?: OrderArticleInterface) {
    this.configuratorModalService.startProcessing();
    this.sessionService.create(article?.id || orderArticle?.id);
    this.configurationArticlesWithMigration = [];

    this.subscriptions.add(
      this.configuratorModalService
        .loadProduct(system, article?.code || orderArticle?.code, articleVariant ? (articleVariant.names as string[])[0] : null, orderArticle ? orderArticle.id : null)
        .subscribe({
          next: (data: ProductResponseInterface) => {
            this.configuratorModalService.setAdditionalData(data.additional);
            this.configuratorModalService.setCurrentPropertyValuesMap(data.data.articlePropertyClasses);
            // display header message if there is any migration issues
            if (data.additional.migrationStatus) {
              this.count = data.additional.migration && data.additional.migration.Changes?.length ? this.countMigrationIssues(data.additional.migration.Changes) : 0;

              if (this.count > 0) {
                this.configuratorModalService.showAllMigrationIssues();
                this.showMigrationIssues = true;
                this.hasMigrationIssues = this.count;

                this.migrationLog = data.additional.migration.Changes;
              }
            }

            // this will transform data from response to internal structure
            this.updateInternals(data);
            this.productsService.getConfigurationArticles().subscribe(res => {
              this.configurationArticles = res;
              this.markConfigurationArticlesAsMigrated(data.additional.migration.Changes);
            });
            this.articleLoadCompleted = true;
            this.configuratorModalService.endProcessing();
            let versionId = data.data.version ?? 0;
            this.configuratorModalService.setArticleVersionId(versionId);
            this.currentArticleVersion = versionId;
          },
          error: error => {
            if (error instanceof HttpErrorResponse && error.status === ERROR_STATUSES.BAD_REQUEST) {
              this.activeModal.close();
              this.configuratorModalService.endProcessing();
            }

            this.failedToLoad = true;
          },
          complete: () => {
            this.configurationLoaded = true;
            this.ngDetectChanges();
          },
        })
    );
  }

  onAddToPriceRequest(eventData: FooterOnAddToPriceRequestEventInterface) {
    const { quantity, priceRequest } = eventData;

    this.activeModal.close();
    
    this.router.navigate([InquiriesRoutePath.ROOT, InquiriesRoutePath.CUSTOM_MADE_PRICE_REQUESTS, priceRequest.id], {
      queryParams: { createBasedOnStandardItem: true, itemId: this.additional.rootId, quantity },
    });
  }

  private countMigrationIssues(migrationLog: MigrationChangeInterface[]): number {
    return migrationLog?.reduce((acc, change) => acc + Object.keys(change.Props).length, 0) ?? 0;
  }

  private markConfigurationArticlesAsMigrated(migrationLog: MigrationChangeInterface[]) {
    this.configurationArticles.forEach(article => {
      if (migrationLog?.find(migration => migration.ItemID === article.id && this.checkForVisibleMigrationIssues(migration))) {
        this.configurationArticlesWithMigration.push(article.id);
      }
    });
  }

  private checkForVisibleMigrationIssues(migration: MigrationChangeInterface): boolean {
    let result = false;
    Object.keys(migration.Props).forEach(key => {
      if (!migration.Props[key].Hidden) {
        if (!this.nameToOriginalNameMap.has(key)) {
          this.nameToOriginalNameMap.set(key, migration.Props[key].FromProperty.Title);
        }

        result = true;

        return;
      }
    });

    return result;
  }

  /**
   * Updates already existing order with different article
   *
   * @param {number} quantity
   * @param {OrderInterface} passedOrder
   * @param {ExtraListElementInterface} group
   */
  onUpdateOrder({ quantity, order: passedOrder, group }: FooterOnOrderEventInterface) {
    const params = { quantity, itemId: this.additional.rootId } as OrderArticleCreateInterface;
    const method = this.orderArticle
      ? this.orderArticleService.update(this.orderArticle.id, { order: passedOrder.id, pageBreak: group?.id ?? null, ...params })
      : this.orderArticleService.create({ order: passedOrder.id, pageBreak: group?.id ?? null, ...params });

    const message = this.orderArticle ? 'CONFIGURATOR_MODAL.ORDER.SUCCESSFULLY_UPDATED' : 'CONFIGURATOR_MODAL.ORDER.SUCCESSFULLY_ORDERED';

    this.loaderOfWholeModal.show();

    zip(method, this.userService.fromStorage()).subscribe(
      ([orderArticle, user]) => {
        this.userService
          .update(user.id, { lastUpdatedOrder: passedOrder.id, lastUpdatedPageBreak: group?.id, lastUpdatedCustomMadePriceRequest: null })
          .subscribe((updatedUser: UserInterface) => {
            this.authService.updateUser(updatedUser);
            this.activeModal.close();
            this.selectionMenuService.close();

            let previewRoute = OrderRoutePath.ORDERS;
            let buttonTextTranslationKey = 'CONFIGURATOR_MODAL.ORDER.REVIEW_OFFER';

            switch (passedOrder.state) {
              case OrderState.DRAFT:
                if (user.role.name === UserRole.ROLE_PM_NARBUTAS) {
                  previewRoute = OrderRoutePath.ORDERS;
                } else {
                  previewRoute = OrderRoutePath.OFFERS;
                }
                break;
              case OrderState.WAITING:
                previewRoute = OrderRoutePath.WAITING;
                buttonTextTranslationKey = 'CONFIGURATOR_MODAL.ORDER.REVIEW_ORDER';
                break;
              default:
                previewRoute = OrderRoutePath.ORDERS;
                buttonTextTranslationKey = 'CONFIGURATOR_MODAL.ORDER.REVIEW_ORDER';
                break;
            }

            this.getTypeAndRouteByAction(updatedUser).subscribe(({ type, route }) => {
              zip(
                this.translator.get(message),
                this.translator.get(buttonTextTranslationKey)
              ).subscribe(([contentText, buttonText]) => {
                this.toastContent = contentText;
                this.toastButtonText = buttonText;
                this.toastUrl = `/${OrderRoutePath.ROOT}/${previewRoute}/${passedOrder.id}`;

                this.toastService.success(this.toastUpdateRef, {
                  delay: 10000
                });
              });
            });
            this.save.emit({ orderArticle, order: passedOrder });
            this.loaderOfWholeModal.hide();
          });
      },

      err => {
        this.translator.get('CONFIGURATOR_MODAL.ORDER.FAILED_TO_PLACE_ORDER').subscribe(translation => {
          this.toastService.danger(translation, { delay: 10000 });
          this.loaderOfWholeModal.hide();
        });
      }
    );
  }

  /**
   * Calls API endpoints and places an order
   *
   * @param {number} quantity
   * @param {OrderInterface} passedOrder
   */
  onCreateOrder({ quantity, order: passedOrder }: FooterOnOrderEventInterface) {
    const { id, ...orderRequest } = passedOrder;

    this.loaderOfWholeModal.show();

    zip(this.ordersService.create(orderRequest), this.userService.fromStorage()).subscribe(([order, user]) => {
      zip(
        this.userService.update(user.id, { lastUpdatedOrder: order.id, lastUpdatedPageBreak: null, lastUpdatedCustomMadePriceRequest: null }),
        this.orderArticle
          ? this.orderArticleService.update(this.orderArticle.id, {
            quantity,
            order: order.id,
            itemId: this.additional.rootId,
          })
          : this.orderArticleService.create({ quantity, order: order.id, itemId: this.additional.rootId })
      ).subscribe(
        ([updatedUser, orderArticle]) => {
          this.authService.updateUser(updatedUser);
          this.activeModal.close();
          this.selectionMenuService.close();

          this.getTypeAndRouteByAction(updatedUser).subscribe(({ type, route }) => {
            const url = this.router.createUrlTree([OrderRoutePath.ROOT, route, order.id]);
            this.translator.get('CONFIGURATOR_MODAL.ORDER.SUCCESSFULLY_ORDERED', { url, type }).subscribe(translation => {
              this.toastService.success(translation, { delay: 10000 });
              this.loaderOfWholeModal.hide();
            });
          });

          this.save.emit({ orderArticle, order });
          this.loaderOfWholeModal.hide();
        },
        err => {
          this.translator.get('CONFIGURATOR_MODAL.ORDER.FAILED_TO_PLACE_ORDER').subscribe(translation => {
            this.toastService.danger(translation, { delay: 10000 });
            this.loaderOfWholeModal.hide();
          });
        }
      );
    });
  }

  /**
   * Sets the selected order
   * @param {OrderInterface} order
   * @param group
   */
  onSelectOrder({ order, group }: OrderSelectOnChangeEventInterface) {
    this.order = order;
    this.group = group;
    this.userService
      .getUser()
      .pipe(
        tap(user => {
          if (this.order.state === OrderState.WAITING || (user && user.role.name === UserRole.ROLE_PM_NARBUTAS)) {
            this.action = ConfiguratorModalActionType.ADD_TO_ORDER;
          } else {
            this.action = ConfiguratorModalActionType.ADD_TO_OFFER;
          }
        }),
        switchMap(user => {
          return this.userService.update(user.id, {
            lastUpdatedOrder: this.order.id,
            lastUpdatedPageBreak: this.group ? this.group.id : null,
          });
        })
      )
      .subscribe();
  }

  onOpenMigrationLog() {
    const modalRef = this.modalService.open(MigrationsSidebarModalComponent, {
      windowClass: 'sidebar-modal',
      modalDialogClass: 'position-fixed h-100 right-0',
    });

    const componentInstance = modalRef.componentInstance as MigrationsSidebarModalComponent;
    componentInstance.migrationLog = this.migrationLog;
    componentInstance.configurationArticles = this.configurationArticles;
    componentInstance.nameToOriginalNameMap = this.nameToOriginalNameMap;
    componentInstance.configurationArticlesWithMigration = this.configurationArticlesWithMigration;

    if (this.lastUsedProperty) {
      componentInstance.lastUsedProperty = this.lastUsedProperty;
    } else if (this.currentArticleId && this.shouldScrollToRelativeMigration) {
      componentInstance.lastUsedProperty = `article_${this.currentArticleId}`;
      componentInstance.scrollToProperty = true;
    }

    this.scrollToRelativeMigration();

    this.subscriptions.add(componentInstance.scrollPosition.subscribe((value) => {
      this.lastScrollPosition = value;
    }));

    this.subscriptions.add(componentInstance.acceptChanges.subscribe(() => {
      this.lastUsedProperty = null;
      modalRef.close();
    }));

    this.subscriptions.add(componentInstance.selectArticle.subscribe((target) => {
      this.lastUsedProperty = `article_${target}`;
      this.selectArticle(target);
      modalRef.close();
    }))

    this.subscriptions.add(componentInstance.selectProperty.subscribe((target) => {
      this.lastUsedProperty = `prop_${target}`; // prefix is needed for css selector in case target starts with a number

      modalRef.close();
      this.configuratorModalService.processingAsObservable().pipe(take(2)).subscribe((isProcessing) => {
        if (isProcessing) {
          return;
        }

        // wait for sidebar to close and try to locate the property component and scroll to it
        setTimeout(() => {
          const propertyElement = document.getElementById(target);
          const firstChild = propertyElement?.querySelector('.migration-issue-container > div') as HTMLElement;

          if (firstChild) {
            firstChild.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            firstChild.classList.add('bg-transition'); // bg animation is defined in css .bg-transition definition
            firstChild.classList.add('bg-warning-300');
            firstChild.classList.remove('bg-gray-200');
            setTimeout(() => {
              firstChild.classList.remove('bg-warning-300');
              firstChild.classList.add('bg-gray-200');
            }, 1000); // time to toggle bg classes
          }
        }, 0);
      });
    }));

    modalRef.dismissed.subscribe(() => {
      this.lastUsedProperty = null;
      this.lastScrollPosition = document.querySelector('migrations-sidebar-modal > div').scrollTop;
    });
  }

  private scrollToRelativeMigration() {
    if (!this.lastScrollPosition) {
      return;
    }

    setTimeout(() => {
      document.querySelector('migrations-sidebar-modal > div').scrollTop = this.lastScrollPosition;
      this.shouldScrollToRelativeMigration = false;
    }, 0);
  }

  private getTypeAndRouteByAction(user: UserInterface): Observable<{ type: string; route: string }> {
    const parentRoute = NavbarElements.find(parent => parent.id === RouteTypeEnums.ORDERS);
    return new Observable(observer => {
      let value = {
        type: 'CONFIGURATOR_MODAL.TYPE.OFFER',
        route: parentRoute.children.find(child => child.id === OrderListRouteType.OFFERS).path,
      };
      if (this.action === ConfiguratorModalActionType.ADD_TO_ORDER) {
        value = {
          type: 'CONFIGURATOR_MODAL.TYPE.ORDER',
          route: parentRoute.children.find(
            child =>
              child.id ===
              (user.role.name === UserRole.ROLE_PM || user.role.name === UserRole.ROLE_PM_RU
                ? OrderListRouteType.WAITING
                : OrderListRouteType.ORDERS)
          ).path,
        };
      }
      this.translator.get(value.type).subscribe(translation => {
        observer.next({ ...value, type: translation.toLowerCase() });
        observer.complete();
      });
    });
  }

  private updateInternals(data: ProductResponseInterface) {
    this.transformedResponse = this.transformDataStructure(data);
    // extract originalName to name map for later use
    const map: Map<string, string> = new Map();
    this.transformedResponse.propertyClasses.forEach(item => item.properties.forEach(item => map.set(item.originalName, item.name)));
    this.nameToOriginalNameMap = map;



    /**
     * @NOTE: Transformed response is passed to the model-viewer component as soon as it is transformed, so the
     * 3D renderer could render it without any delay
     */
    this.additional = this.transformedResponse.additional;
    const component = this.propertiesConfiguratorComponentFactory.create(this.propertiesConfigurator, this.transformedResponse);
    component.instance.propertyChange.subscribe((event: PropertyValueChangeEventInterface) => {
      this.onPropertyClassChange({ ...event, orderArticle: this.orderArticle ? this.orderArticle.id : null });
    });

    this.propertiesConfiguratorComponentRef = component;
  }

  selectArticle(articleId: string | null) {
    if (this.currentArticleId === articleId) {

      return;
    }

    this.configuratorModalService.startProcessing();
    this.productsService.selectProduct(articleId).subscribe(response => {
      this.updateInternals(response);
      this.configuratorModalService.setSelectedArticleId(articleId);
      this.orderArticleChanged = true;
      this.configuratorModalService.endProcessing();
      this.ngDetectChanges();
    });
  }

  ngDetectChanges() {
    this.updated++;
    this.changeDetectorRef.detectChanges();
  }

  public getRootArticle(): MeshExtended {
    return this.articleRef.node || null; 
  }

  onAdvertisementBlockClick(): void {
    const modalRef: NgbModalRef = this.selectionMenuService.openSelectionMenuModal();

    const instance = modalRef.componentInstance as SelectionMenuComponent;
    instance.activeTab = TabTypes.PRICE_REQUESTS;

    this.subscriptions.add(
      this.selectionMenuService
        .openObservable()
        .pipe(
          skip(1),
          filter((open) => !open),
          take(1),
          takeUntil(modalRef.dismissed)
        )
        .subscribe(() => {
          modalRef.close();
        })
    );
  }

  private onArticleSelect(nextId: string) {
    this.shouldScrollToRelativeMigration = true;

    if (null === nextId) {
      nextId = this.getRootArticle().getArticleId();
    }

    this.configuratorModalService.startProcessing();
    this.productsService.selectProduct(nextId).subscribe(response => {
      this.updateInternals(response);
      this.configuratorModalService.endProcessing();
      this.ngDetectChanges();
    });
  }

  private transformDataStructure(response: ProductResponseInterface): TransformedResponseInterface {
    const { data, additional } = response;

    if (!environment.production) {
      additional.configuratorUrl = additional.configuratorUrl.replace(
        'https://configurator1.dev.nspace.narbutas.com',
        'http://94.130.198.150'
      );
    }

    const { articlePropertyClasses } = data;
    const propertyClasses = PropertyClassGeneratorService
      .customizeAndFilterPropertyClasses(
        articlePropertyClasses.filter(propertyClass => {

          return propertyClass.properties.length > 0;
        }),
        data.configuratorId
      );

    const groupedPropertyClasses = (new PropertyClassesBuilder(propertyClasses))
      .determineTypes()
      .removeEmptyValues()
      .group()
      .hideHidden()
      .flagAdded()
      .build();

    return {
      additional: additional,
      propertyClasses: groupedPropertyClasses,
      resolvedMediaType: data.resolvedMediaType,
      fullCode: data.fullCode,
      controllingProperties: data.controllingProperties,
      configuratorId: data.configuratorId,
    };
  }
}
