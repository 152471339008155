import type { CountryInterface } from "./country.model";
import type { CurrencyInterface } from "./currency.model";
import { BaseFileInterface, BaseFileWrapperInterface } from "./file.model";

export enum ProjectReservationStatusEnum {
  DRAFT = 'draft',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  WAITING_FOR_RESPONSE = 'waiting_for_response',
  OPEN_TENDER = 'open_tender',
  CONFIRMED = 'confirmed',
  NEED_TO_REVIEW = 'need_to_review',
  REJECTED = 'rejected',
  LOST = 'lost',
  WON = 'won',
  PARTIALLY_APPLIED = 'partially_applied',
  APPLIED = 'applied',
  EXPIRED = 'expired',
}

export const ProjectReservationStatusTranslationMap = {
  [ProjectReservationStatusEnum.DRAFT]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.DRAFT',
  [ProjectReservationStatusEnum.NEW]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.NEW',
  [ProjectReservationStatusEnum.IN_PROGRESS]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.IN_PROGRESS',
  [ProjectReservationStatusEnum.WAITING_FOR_RESPONSE]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.WAITING_FOR_RESPONSE',
  [ProjectReservationStatusEnum.OPEN_TENDER]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.OPEN_TENDER',
  [ProjectReservationStatusEnum.CONFIRMED]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.CONFIRMED',
  [ProjectReservationStatusEnum.NEED_TO_REVIEW]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.NEED_TO_REVIEW',
  [ProjectReservationStatusEnum.REJECTED]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.REJECTED',
  [ProjectReservationStatusEnum.LOST]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.LOST',
  [ProjectReservationStatusEnum.WON]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.WON',
  [ProjectReservationStatusEnum.PARTIALLY_APPLIED]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.PARTIALLY_APPLIED',
  [ProjectReservationStatusEnum.APPLIED]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.APPLIED',
  [ProjectReservationStatusEnum.EXPIRED]: 'INQUIRIES.PROJECT_RESERVATIONS.STATUS.EXPIRED',
};

export enum ProjectReservationOutcomeEnum {
  WON = 'won',
  LOST = 'lost',
}

export interface ProjectReservationInterface {
  id: number;
  title: string;
  status: ProjectReservationStatusEnum;
  outcome: ProjectReservationOutcomeEnum | null;
  country: CountryInterface;
  city: string;
  value: number;
  currency: CurrencyInterface;
  workplaceCount: number | null;
  productSystems: string[];
  conditions: string | null;
  rejectionReasons: string | null;
  lostReasons?: ProjectReservationLostReasonsInterface[] | null;
  otherLostReasons: string | null;
  description: string | null;
  upcomingOrdersCount: number | null;
  jiraIssueKey: string | null;
  unseenMessagesCount?: number;
  architect?: string;
  type?: string;
  dealerValue?: number;
  contactInformation?: string | null;
  projectReservationFiles?: ProjectReservationFileWrapperInterface[];

  /** Timestamp in seconds */
  updatedAtTimestamp: number;
  /** Timestamp in seconds */
  expirationDateTimestamp: number | null;

  /** Timestamp in seconds */
  estimatedWinDateTimestamp: number;

  /** Timestamp in seconds */
  executionDateTimestamp: number;
}

export enum ProjectReservationReasonsEnum {
  TOO_LONG_PRODUCTION_LEAD_TIME = "tooLongProductionLeadTime",
  TOO_HIGH_PRICE = "tooHighPrice",
  TOO_LONG_RESPONSE_TIME = "tooLongResponseTime",
  LACK_OF_ADDITIONAL_SUPPORT = "lackOfAdditionalSupport",
  PRODUCT_OR_COMPANY_REQUIREMENTS_NOT_MET = "productOrCompanyRequirementsNotMet",
  PROJECT_WAS_CANCELED = "projectWasCanceled",
  OTHER_REASONS = "otherReasons"
}

export interface ProjectReservationLostReasonsInterface {
  [ProjectReservationReasonsEnum.TOO_LONG_PRODUCTION_LEAD_TIME]?: null | boolean;
  [ProjectReservationReasonsEnum.TOO_HIGH_PRICE]?: null | boolean;
  [ProjectReservationReasonsEnum.TOO_LONG_RESPONSE_TIME]?: null | boolean;
  [ProjectReservationReasonsEnum.LACK_OF_ADDITIONAL_SUPPORT]?: null | boolean;
  [ProjectReservationReasonsEnum.PRODUCT_OR_COMPANY_REQUIREMENTS_NOT_MET]?: null | boolean;
  [ProjectReservationReasonsEnum.PROJECT_WAS_CANCELED]?: null | boolean;
  [ProjectReservationReasonsEnum.OTHER_REASONS]?: null | boolean;
}

export interface ProjectReservationLostModalFormInterface extends Pick<ProjectReservationInterface, 'otherLostReasons'>, ProjectReservationLostReasonsInterface {
}

export interface ProjectReservationCodeSuggestionInterface {
  title: string;
  code: string;
}

export interface ProjectReservationFileInterface extends BaseFileInterface {
  title: string;
}

export interface ProjectReservationFileWrapperInterface extends BaseFileWrapperInterface<ProjectReservationFileInterface> {
  id: number;
}
