<div class="options-list">
  <div class="pt-4 mt-2 options-list__block">
    <div class="row">
      <div class="col-6">
        <app-ui-elements-form-drop-down
          [values]="data.templates"
          [title]="'CREATE_DOCUMENT.TEMPLATE.TEMPLATE' | translate"
          [selectedValue]="selectedTemplate.id"
          labelField="title"
          (onChange)="onTemplateChange($event)"
        ></app-ui-elements-form-drop-down>
      </div>
      <div class="col-6">
        <app-ui-elements-form-drop-down
          [values]="languages"
          [title]="'CREATE_DOCUMENT.TEMPLATE.LANGUAGES' | translate"
          [selectedValue]="data.language"
          (onChange)="onLanguageChange($event)"
          labelField="translated"
          valueField="id"
        ></app-ui-elements-form-drop-down>
      </div>
    </div>
    <div class="mt-3 row">
      <div class="col-6">
        <app-ui-elements-form-drop-down
          [values]="currencies"
          [invalid]="!selectedTemplate.currency && showErrors"
          [title]="'CREATE_DOCUMENT.TEMPLATE.CURRENCY' | translate"
          [selectedValue]="selectedTemplate.currency"
          labelField="title"
          (onChange)="onCurrencyChange($event)"
          [groups]="[currencyDisplayTypes.NAME, currencyDisplayTypes.SYMBOL]"
          groupLabel="content"
          [selectedGroup]="selectedTemplate.currencyDisplayType"
        ></app-ui-elements-form-drop-down>
      </div>
      <div class="col-6">
        <app-text-field
          class="document-template__field"
          [value]="selectedTemplate.exchangeRate"
          [theme]="[
            textFieldThemeTypes.TEXTAREA_INLINE,
            textFieldThemeTypes.TOOLTIP_ERROR,
            textFieldThemeTypes.INLINE,
            textFieldThemeTypes.NEW
          ]"
          [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.EXCHANGE_RATE' | translate) + ':'"
          [placeholder]="'CREATE_DOCUMENT.TEMPLATE.FORM.EXCHANGE_RATE_PLACEHOLDER' | translate"
          [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.EXCHANGE_RATE' | translate"
          name="exchangeRate"
          (isFocused)="onExchangeRateChange($event)"
          [formControl]="form.controls.exchangeRate"
          [shouldInstantlyChange]="false"
        >
        </app-text-field>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loading; else loader">
    <div class="mt-2 border-bottom options-list__heading">
      <p class="fw-bold mb-2 color-gray">{{ 'CREATE_DOCUMENT.TEMPLATE.HEADLINE' | translate }}</p>
    </div>
    <div class="mt-3 options-list__block">
      <app-template-headline
        [data]="data"
        [selectedTemplate]="selectedTemplate"
        [backgroundType]="backgroundType"
        [customerDataTypes]="customerDataTypes"
      ></app-template-headline>
    </div>

    <ng-container *ngIf="selectedTemplate.type === documentTemplateTypes.INVOICE">
      <div class="mt-2 border-bottom options-list__heading">
        <p class="fw-bold mb-2 color-gray">{{ 'CREATE_DOCUMENT.TEMPLATE.DETAILS' | translate }}</p>
      </div>
      <div class="mt-3 options-list__block">
        <app-document-template-details
          [customerDataTypes]="customerDataTypes"
          [selectedTemplate]="selectedTemplate"
          [data]="data"
        ></app-document-template-details>
      </div>
    </ng-container>

    <div class="mt-2 border-bottom options-list__heading">
      <p class="fw-bold mb-2 color-gray">{{ 'CREATE_DOCUMENT.TEMPLATE.PRODUCT_LIST' | translate }}</p>
    </div>
    <div class="mt-3 options-list__block options-list__block--product-list">
      <app-template-product-list [selectedTemplate]="selectedTemplate"></app-template-product-list>
    </div>

    <ng-container *ngIf="selectedTemplate.type === documentTemplateTypes.INVOICE">
      <div class="mt-2 border-bottom options-list__heading">
        <p class="fw-bold mb-2 color-gray">{{ 'CREATE_DOCUMENT.TEMPLATE.PAYMENT_CONDITIONS.TITLE' | translate }}</p>
      </div>
      <div class="mt-3 options-list__block">
        <app-document-template-payment-conditions
          [currency]="selectedTemplate.currency ? selectedTemplate.currency[selectedTemplate.currencyDisplayType] : null"
          [exchangeRate]="selectedTemplate.exchangeRate"
        ></app-document-template-payment-conditions>
      </div>
    </ng-container>

    <ng-container>
      <div class="mt-2 border-bottom options-list__heading">
        <p class="fw-bold mb-2 color-gray">{{ 'CREATE_DOCUMENT.TEMPLATE.FOOTER' | translate }}</p>
      </div>
      <div class="mt-3 options-list__block">
        <app-template-footer [selectedTemplate]="selectedTemplate"></app-template-footer>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loader>
    <app-loader [loading]="loading" [cssClasses]="'loader--relative'"></app-loader>
  </ng-template>
</div>
