import { ProjectReservationUpdateInterface, projectTypeOptions } from './../../project-reservation.service';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Observable, Subscription, debounceTime, distinctUntilChanged, map, skip, startWith } from 'rxjs';
import { CategoryPathInterface } from '../../../core/models/category-path.model';
import { CountryInterface } from '../../../core/models/country.model';
import { ProjectReservationInterface, ProjectReservationFileInterface, ProjectReservationFileWrapperInterface } from '../../../core/models/project-reservation.model';
import { CountryService } from '../../../core/services/country/country.service';
import { UserService } from '../../../core/services/user/user.service';
import * as CustomValidators from '../../../shared/class/custom-validators';
import { minCurrencyAmount, numbers } from '../../../shared/class/custom-validators';
import { FormNativeElementDirective } from '../../../shared/directives/form-native-element/form-native-element.directive';
import { SharedModule } from '../../../shared/shared.module';
import { DateInputComponent } from '../../../ui-elements/date-input/date-input.component';
import { MinDateNowDirective } from '../../../ui-elements/date-input/min-date-now.directive';
import {
  ProjectReservationCreateInterface,
  ProjectReservationResponseInterface,
  ProjectReservationService,
  ProjectReservationWonUpdateInterface
} from '../../project-reservation.service';
import { ProjectReservationPreviewComponent } from '../project-reservation-preview/project-reservation-preview.component';
import { AlertComponent } from "../../../shared/components/alert/alert.component";
import { FileInputComponent } from '../../../shared/components/file-input/file-input.component';

export interface SelectedProductSystemInterface {
  name: string;
}

@Component({
    selector: 'app-project-reservation-modal',
    templateUrl: './project-reservation-modal.component.html',
    styleUrls: ['./project-reservation-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule, FormNativeElementDirective, DateInputComponent, MinDateNowDirective, AlertComponent, FileInputComponent]
})

export class ProjectReservationModalComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup = null;
  countries: CountryInterface[];
  selectedProductSystems: SelectedProductSystemInterface[] = [];
  productSystemsList: (CategoryPathInterface | SelectedProductSystemInterface)[] = [];

  productSystemTypeaheadLimit = 15;
  isDisabled = false;
  preventSubmit = false;
  updateMode = false;
  draftEditMode = false;
  userCountryId: CountryInterface['id'] = 0;
  fileUploadEndpoint: string;
  projectTypes = projectTypeOptions;
  userCurrencySymbol = '&euro;';

  fileDeleteHandler = null;

  protected subscriptions: Subscription = new Subscription();
  projectReservationItem: ProjectReservationInterface;

  @ViewChild('projectReservationToast') public projectReservationToastRef: TemplateRef<any>;
  @ViewChild('projectReservationFileInput') public projectReservationFileInputRef: FileInputComponent<ProjectReservationFileInterface, ProjectReservationFileWrapperInterface>;
  @Output() saved: EventEmitter<ProjectReservationResponseInterface> = new EventEmitter<ProjectReservationResponseInterface>();
  @Output() updated: EventEmitter<ProjectReservationWonUpdateInterface> = new EventEmitter<ProjectReservationWonUpdateInterface>();
  @Output() canceled: EventEmitter<ProjectReservationInterface> = new EventEmitter<ProjectReservationInterface>();

  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  constructor(
    protected activeModal: NgbActiveModal,
    protected projectReservationService: ProjectReservationService,
    protected countryService: CountryService,
    private modalService: NgbModal,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.add(this.userService.fromStorage().subscribe(user => {
      this.userCountryId = user.country.id;
    }));
  }

  ngOnInit() {

    if (!this.form) {
      this.constructForm();
    }

    this.subscriptions.add(this.countryService.all().subscribe(result => {
      this.countries = result;
    }));

    this.subscriptions.add(this.projectReservationService.getProductSystems().subscribe(result => {
      this.productSystemsList = result.data.map((item) => {
        if (this.selectedProductSystems.find((selected) => selected.name === item.name)) {
          return { ...item, disabled: true };
        }

        return item;
      });
    }));

    this.subscriptions.add(
      this.userService.userObservable().subscribe(user => {
        this.userCurrencySymbol = user.priceList.currency.name;

        this.form.get('value').addValidators([
          minCurrencyAmount(25000, this.userCurrencySymbol)
        ]);
        this.form.get('dealerValue').addValidators([
          minCurrencyAmount(25000, this.userCurrencySymbol)
        ]);
    }));

    this.subscriptions.add(
      this.form.valueChanges.subscribe((formChanges) => {
        this.form.patchValue(
          {
            value: formChanges.value?.toString().replace(/\D/g, ''),
            dealerValue: formChanges.dealerValue?.toString().replace(/\D/g, '')
          },
          { emitEvent: false }
        );
      })
    );

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          debounceTime(2000),
          startWith(this.form.getRawValue()),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify({...previous, id: current.id }) === JSON.stringify(current);
          }),
          skip(1),
        )
        .subscribe(() => {
          this.onSubmit(true);
        })
    );
  }

  ngAfterViewInit() {
    const itemId = this.form.getRawValue().id || 0;

    if ((this.draftEditMode || this.updateMode) && +itemId > 0) {
      this.fileUploadEndpoint = this.projectReservationService.getFileUploadEndpoint(itemId);
      this.fileDeleteHandler = this.projectReservationService.getFileDeleteHandler(itemId);
      const fileInputRef = this.projectReservationFileInputRef;
      fileInputRef?.updateDropEndpoint(this.fileUploadEndpoint);
      this.changeDetectorRef.detectChanges();
    }
  }

  constructForm(fields?: ProjectReservationInterface, disabled: boolean = false) {
    this.preventSubmit = false;
    this.isDisabled = disabled;
    if (fields?.productSystems.length) {
      this.selectedProductSystems = fields.productSystems.map(item => {
        return { name: item } as SelectedProductSystemInterface
      });
    }

    const estimatedWinDateFormatted = fields?.estimatedWinDateTimestamp ? moment.unix(fields?.estimatedWinDateTimestamp).format("YYYY-MM-DD") : undefined;
    const executionDateFormatted = fields?.executionDateTimestamp ? moment.unix(fields?.executionDateTimestamp).format("YYYY-MM-DD") : undefined;

    this.form = new FormGroup({
      id: new FormControl<number>({ value: fields?.id, disabled: disabled }, []),
      title: new FormControl<string>({ value: fields?.title, disabled: disabled }, [ Validators.required ]),
      country: new FormControl<number>({ value: fields?.country.id || this.userCountryId || null, disabled: disabled }, [ Validators.required ]),
      city: new FormControl<string>({ value: fields?.city, disabled: disabled }, [ Validators.required ]),
      value: new FormControl<number>({ value: fields?.value, disabled: disabled }, [ Validators.required ]),
      workplaceCount: new FormControl<number>({ value: fields?.workplaceCount, disabled: disabled }, [ numbers, ]),
      estimatedWinDate: new FormControl<string>({ value: estimatedWinDateFormatted, disabled: disabled || this.updateMode }, [
        Validators.required,
        CustomValidators.validDateFormat,
        (this.updateMode ? CustomValidators.validDateFormat : CustomValidators.minDate(new Date()))
      ]),
      executionDate: new FormControl<string>({ value: executionDateFormatted, disabled: disabled }, [
        Validators.required,
        CustomValidators.validDateFormat,
        CustomValidators.minDate(new Date())
      ]),
      productSystems: new FormControl<string[]>({ value: fields?.productSystems, disabled: disabled }, [ Validators.required ]),
      description: new FormControl<string>({ value: fields?.description, disabled: disabled }, []),
      outcome: new FormControl<string>({ value: fields?.outcome, disabled: disabled }, []),
      upcomingOrdersCount: new FormControl<number>({ value: fields?.upcomingOrdersCount, disabled: disabled }, this.updateMode ? [ Validators.required ] : []),
      architect: new FormControl<string>({ value: fields?.architect, disabled: disabled }, []),
      type: new FormControl<string>({ value: fields?.type, disabled: disabled }, [ Validators.required ]),
      dealerValue: new FormControl<number>({ value: fields?.dealerValue, disabled: disabled }, [ ]),
      contactInformation: new FormControl<string>({ value: fields?.contactInformation, disabled: disabled }, [ Validators.required ]),
      projectReservationFiles: new FormControl<ProjectReservationFileWrapperInterface[]>({ value: this.updateMode ? [] : fields?.projectReservationFiles || [], disabled: disabled }, []),
    });
  }

  onSubmit(noValidate = false) {
    if (!noValidate) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }


    if ((!noValidate && this.form.invalid) || this.preventSubmit) {
      return;
    }

    this.preventSubmit = true;
    const formData: ProjectReservationCreateInterface|ProjectReservationUpdateInterface = this.form.getRawValue();

    Object.keys(formData).forEach((item) => {
      if (!item || item === undefined) {
        delete formData[item];
      }
    });

    // files must be dropped because we have separate endpoint for uploading them
    const fileCount = formData.projectReservationFiles?.length || 0;
    delete formData.projectReservationFiles;

    if ('id' in formData && formData.id) {
      const updateDta = { ...formData };
      delete updateDta.id;
      delete updateDta.outcome;
      delete updateDta.upcomingOrdersCount;

      this.subscriptions.add(this.projectReservationService.update(formData.id, updateDta, !noValidate).subscribe((response) => {
        this.saved.emit(response);
        this.preventSubmit = false;

        this.handleModalClose(noValidate);
      }));

      return;
    }

    this.subscriptions.add(this.projectReservationService.create(formData).subscribe((response) => {
      const fileInputRef = this.projectReservationFileInputRef;
      if (!this.fileUploadEndpoint?.length) {
        this.fileUploadEndpoint = this.projectReservationService.getFileUploadEndpoint(response.data.id);
        this.fileDeleteHandler = this.projectReservationService.getFileDeleteHandler(response.data.id);
        fileInputRef.updateDropEndpoint(this.fileUploadEndpoint);
      }

      if (!fileCount) {
        this.saved.emit(response);
        this.form.patchValue({...formData, id: response.data.id});
        this.preventSubmit = false;

        this.handleModalClose(noValidate);

        return;
      }

      // save uploaded files
      fileInputRef.fileAdded.subscribe((res: ProjectReservationFileInterface) => {
        this.saved.emit(response);
        this.preventSubmit = false;

        this.handleModalClose(noValidate);
      });
      fileInputRef.fileUploadFailed.subscribe((res: ProjectReservationFileInterface) => {
        this.saved.emit(response);
        this.preventSubmit = false;

        this.handleModalClose(noValidate);
      });

      fileInputRef?.triggerQueue();
    }));
  }

  handleModalClose(noValidate = false) {
    if (!noValidate) {
      this.activeModal.close();

      return;
    }

    this.draftEditMode = true;
  }

  onUpdate() {
    if (this.form.invalid || this.preventSubmit) {
      return;
    }

    this.preventSubmit = true;
    const formData: ProjectReservationWonUpdateInterface = this.form.getRawValue();

    const updateData: ProjectReservationWonUpdateInterface = {
      lostReasons: formData.lostReasons,
      otherLostReasons: formData.otherLostReasons,
      outcome: formData.outcome,
      upcomingOrdersCount: formData.upcomingOrdersCount,
      value: formData.value,
      executionDate: moment(formData.executionDate).format('YYYY-MM-DD'),
      productSystems: formData.productSystems,
      description: formData.description,
      workplaceCount: formData.workplaceCount,
      dealerValue: formData.dealerValue,
      contactInformation: formData.contactInformation,
    };

    this.updated.emit(updateData);
    this.activeModal.close();
  }

  removeSelectedSystem(system: CategoryPathInterface | SelectedProductSystemInterface) {
    this.selectedProductSystems = this.selectedProductSystems.filter(item => item !== system);
    this.form.patchValue({ productSystems: this.selectedProductSystems.map(item => item.name) });
    this.form.markAsDirty();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) =>
        term === ''
          ? []
          : this.findMatch(term),
      ),
    );

  private markTypeaheadButtonsDisabled() {
    // there is no way to set some properties directly on typeahead button, only on child of that button
    // so to mark button disabled we will reflect disabled state from child div class
    const container = document.querySelectorAll('ngb-typeahead-window.product-systems-typeahead button');
    container.forEach((i: HTMLButtonElement) => i.querySelector('div.disabled') ? i.disabled = true : '');
  }

  private findMatch(term: string) {
    const defaultEntry: SelectedProductSystemInterface = {
      name: 'NON STANDARD', // this serves like product system name which we do not translate
    };

    const matches = this.productSystemsList
      .map((v) => this.selectedProductSystems.includes(v) ? {...v, disabled: true} : v)
      .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, this.productSystemTypeaheadLimit);

    setTimeout(() => {
      // wait a moment for typeahead dom to be already updated
      this.markTypeaheadButtonsDisabled();
    }, 0);

    if (!matches.length) {
      return [defaultEntry];
    }

    return matches;
  }

  onSystemSelected($event: NgbTypeaheadSelectItemEvent, input: HTMLInputElement) {
    $event.preventDefault();
    if ($event.item.disabled) {
      input.value = '';

      return;
    }

    if (this.selectedProductSystems.indexOf($event.item) === -1) {
      this.selectedProductSystems.push($event.item);
    }

    this.form.patchValue({ productSystems: this.selectedProductSystems.map(item => item.name) });
    this.form.markAsDirty();

    input.value = '';
  }

  onTouchedCallback(input: HTMLInputElement) {
    input.value = '';
    this.form.controls.productSystems.markAsTouched();
  }

  onToastButtonClick() {
    const modalRef = this.modalService.open(ProjectReservationPreviewComponent, {
      fullscreen: true,
      keyboard: true,
    });

    const modalInstance: ProjectReservationPreviewComponent = modalRef.componentInstance;
    modalInstance.item = this.projectReservationItem;
    this.subscriptions.add(modalInstance.onProjectReservationSaved.subscribe(() => {
      modalRef.close();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
