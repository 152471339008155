import {Injectable} from '@angular/core';
import {RemoveRowInterface} from "../../../can-modify-product-view.component";
import {ExtraListElementRowInterface} from '../../order-articles-list/components/extra-row/extra-items.model';
import {UngroupEventInterface} from '../../order-articles-list/components/group/group.component';
import {OrderArticlesListRow, OrderArticlesListRowInterface} from '../../order-articles-list.interface';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderArticlesRowsService {
  updateExtraRow: Subject<ExtraListElementRowInterface> = new Subject<ExtraListElementRowInterface>();
  removeExtraRow: Subject<RemoveRowInterface[]> = new Subject<RemoveRowInterface[]>();
  ungroup: Subject<UngroupEventInterface[]> = new Subject<UngroupEventInterface[]>();

  applyCustomDiscount: Subject<OrderArticlesListRowInterface> = new Subject<OrderArticlesListRowInterface>();

  rows: BehaviorSubject<OrderArticlesListRow[]> =
    new BehaviorSubject<OrderArticlesListRow[]>([]);

  private migrationIssuesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getMigrationIssuesCountAsObservable() {
    return this.migrationIssuesCount$.asObservable();
  }

  setMigrationIssuesCount(count: number) {
    this.migrationIssuesCount$.next(count);
  }
}
