export enum FieldType {
  SHORT_TEXT = 'shortText', //Description
  IMG = 'img',
  SYSTEM = 'system',
  CODE = 'code',
  CATEGORY = 'category',

  ORDER_ARTICLE_MATERIALS = 'orderArticleMaterials', //Item materials
  ORDER_ARTICLE_MATERIALS_INLINE = 'orderArticleMaterialsInline', //Item details

  DIMENSIONS = 'dimensions',
  WEIGHT = 'weight',
  VOLUME = 'volume',

  DISCOUNT = 'discount',
  NET_PRICE = 'netPrice',

  TYPE = 'type',
  TITLE = 'title',
  PRICE = 'price',
}
