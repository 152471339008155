import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FieldClass } from '../../../../../../core/enums/field-class';
import { InquiriesRoutePath } from '../../../../../../core/enums/route-types.enum';
import { FieldService } from '../../../../../../core/services/field/field.service';
import { UserService } from '../../../../../../core/services/user/user.service';
import { ListModeSwitchService } from '../../../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { FieldType } from '../../../../../../shared/directives/hide-by-field-type/field-types.enum';
import { OrderArticlesRowsService } from '../../../../services/order-articles-rows/order-articles-rows.service';
import { SelectedRowsService } from '../../../../services/selected-rows/selected-rows.service';
import { ExtraRowComponentModel } from '../../../models/extra-row-component.model';
import { ExtraItemsService } from '../../extra-row/extra-items.service';
import { OpenGroupsService } from '../../group/open-groups/open-groups.service';
import { PriceRequestStatusEnum, PriceRequestStatusTranslationMap } from '../../../../../../core/models/price-request.model';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-order-articles-price-request-items-group',
  templateUrl: './order-articles-price-request-items-group.component.html',
  styleUrls: ['./order-articles-price-request-items-group.component.scss'],
})
export class OrderArticlesPriceRequestItemsGroupComponent extends ExtraRowComponentModel implements OnInit, AfterViewInit {
  isDiscountFieldVisible: boolean;
  isOpen = false;
  isPM = false;

  statusTranslations = PriceRequestStatusTranslationMap;

  constructor(
    protected fb: FormBuilder,
    protected extraListElementService: ExtraItemsService,
    protected selectedRowsService: SelectedRowsService,
    private openGroupsService: OpenGroupsService,
    protected listModeSwitchService: ListModeSwitchService,
    protected userService: UserService,
    protected orderArticlesRowsService: OrderArticlesRowsService,
    protected cdr: ChangeDetectorRef,
    private fieldService: FieldService,
    private router: Router
  ) {
    super(fb, extraListElementService, selectedRowsService, listModeSwitchService, orderArticlesRowsService, cdr, userService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.add(
      this.openGroupsService.getOpenGroupsAsObservable().subscribe(() => {
        this.isOpen = this.openGroupsService.isOpen(this.row);
      })
    );

    this.subscriptions.add(
      this.fieldService.getOptionsAsObservable(FieldClass.ORDER).subscribe((fieldGroups) => {
        this.isDiscountFieldVisible = !!fieldGroups
          .flatMap((group) => group.values)
          .filter((value) => value.checked && value.name === FieldType.DISCOUNT).length;
      })
    );

    this.isPM = this.userService.isPM();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  get isCanceledOrExpired(): boolean {
    if (!this.row.customMadePriceRequest) {
      return false;
    }

    return [PriceRequestStatusEnum.CANCELED, PriceRequestStatusEnum.EXPIRED].includes(this.row.customMadePriceRequest?.status);
  }

  get inputSize(): number {
     /* since size of different characters might differ (+ 2) will add
      additional space to compensate to a certain extent for that */
    return this.titleInput?.nativeElement.value.length + 2 || 1;
  }

  onToggleGroup(): void {
    this.openGroupsService.toggle(this.row);
  }

  onGroupSelect(selected: boolean): void {
    this.selectedRowsService.onGroupSelect(this.row, selected);
  }

  onOpenPriceRequest(): void {
    this.router.navigate([InquiriesRoutePath.ROOT, InquiriesRoutePath.CUSTOM_MADE_PRICE_REQUESTS, this.row.customMadePriceRequest.id]);
  }

  onOpenPriceRequestInSD(): void {
    window.open(`${environment.serviceDeskURL}${this.row.customMadePriceRequest.jiraIssueKey}`, '_blank');
  }
}
