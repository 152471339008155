import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import FeatureFlags from '../../../../../../../featureFlags.json';
import { FieldClass } from '../../../../../core/enums/field-class';
import { OrderArticleSaleDiscountType } from '../../../../../core/enums/order-article-discount-type.enum';
import { OrderState } from "../../../../../core/enums/order.state.enum";
import { UserRole } from '../../../../../core/enums/user-role.enum';
import { OrderArticleMigrationStatusType, OrderArticleType } from '../../../../../core/models/order-article.model';
import { UserInterface } from '../../../../../core/models/user.model';
import { FieldService } from '../../../../../core/services/field/field.service';
import { LanguageStoreHandlerService } from '../../../../../core/services/language/language-store-handler.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { PermissionActions } from '../../../../../permissions.config';
import { ListModeSwitchService } from '../../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { SaleMode } from '../../../../../shared/components/list-mode-switch/sale-mode.types';
import { ClickStopPropagationDirective } from '../../../../../shared/directives/click-stop-propagation/click-stop-propagation.directive';
import { FieldType } from '../../../../../shared/directives/hide-by-field-type/field-types.enum';
import { HideByFieldTypeDirective } from '../../../../../shared/directives/hide-by-field-type/hide-by-field-type.directive';
import { ImageSizeEnum } from '../../../../../shared/pipes/resize-image/resize-image.pipe';
import { SharedModule } from '../../../../../shared/shared.module';
import { CheckMarkComponent } from '../../../../../ui-elements/check-mark/check-mark/check-mark.component';
import { MaterialsListComponent } from '../../../../../ui-elements/materials-list/materials-list.component';
import { OrderArticleMaterialInputService } from '../../../../../ui-elements/order-article-material-input/order-article-material-input.service';
import { OrderArticleMaterialInterface } from '../../../../../ui-elements/order-article-material-input/order-article-material-interface';
import { PopoverRef } from '../../../../../ui-elements/popover/popover-ref';
import { VisibilityByVariablesComponent } from '../../../../../visibility-by-variables/visibility-by-variables.component';
import { DragHandleDirective } from '../../../draggable/directives/ngx-drag-handle.directive';
import {
  OrderArticlesListColumns,
  OrderArticlesListRow,
  OrderArticlesListRowInterface,
  OrderArticlesListRowItemInterface,
  OrderArticlesListRowSizeInterface
} from '../../../order-articles-list.interface';
import { OrderArticleRowsFilteringService } from '../../../services/order-article-rows-filtering/order-article-rows-filtering.service';
import { OrderArticlesRowsService } from '../../../services/order-articles-rows/order-articles-rows.service';
import { SelectedRowsService } from '../../../services/selected-rows/selected-rows.service';
import { SelectableRowInterface } from '../../models/selectable-row.interface';
import { COLUMNS } from '../../order-articles-list-columns.constants';
import { DiscountPipe } from '../../pipes/discount.pipe';
import { FullCodesPipe } from '../../pipes/full-codes.pipe';
import { RowDetailsListComponent } from './row-details-list/row-details-list.component';

@Component({
    selector: 'app-order-articles-list-row',
    templateUrl: './row.component.html',
    styleUrls: ['./row.component.scss'],
    imports: [
      SharedModule,
      HideByFieldTypeDirective,
      DragHandleDirective,
      ClickStopPropagationDirective,
      CheckMarkComponent,
      MaterialsListComponent,
      VisibilityByVariablesComponent,
      RowDetailsListComponent,
      FullCodesPipe,
      DiscountPipe,
    ],
})
export class RowComponent implements OnInit, OnChanges, SelectableRowInterface, OnDestroy {
  @Input() row: OrderArticlesListRowInterface;
  @Input() columns: OrderArticlesListColumns = COLUMNS;
  @Input() selectedRows: OrderArticlesListRow[];
  @Input() limitedView?: boolean;
  @Input() orderState?: OrderState;
  @Input() hasDiscounts = true;

  private subscription: Subscription = new Subscription();

  fieldTypes = FieldType;
  saleMode = SaleMode;
  orderArticleTypes = OrderArticleType;
  invalidRow = false;

  selectedMode: SaleMode;
  openRowDetails: number;

  permissionActions = PermissionActions;

  saleDiscountTypes = OrderArticleSaleDiscountType;
  migrationTypes = OrderArticleMigrationStatusType;
  selected: boolean;
  user: UserInterface;
  userRole = UserRole;
  availableMaterials: OrderArticleMaterialInterface[] = [];
  rowMaterials: OrderArticleMaterialInterface[] = [];
  popover: PopoverRef;
  fieldsToShow = [];

  price: string;
  uiLanguage = 'en';
  public orderStates = OrderState;
  imageSizes = ImageSizeEnum;
  isFilterEnabled = false;

  constructor(
    private listModeSwitchService: ListModeSwitchService,
    private selectedRowsService: SelectedRowsService,
    private orderArticlesRowsService: OrderArticlesRowsService,
    private userService: UserService,
    private orderArticleMaterialInputService: OrderArticleMaterialInputService,
    private fieldService: FieldService,
    private languageStore: LanguageStoreHandlerService,
    private orderArticleRowsFilteringService: OrderArticleRowsFilteringService
  ) {}

  ngOnInit() {
    this.initializeMaterials();

    this.subscription.add(
      this.languageStore.getCurrentAsObservable().subscribe(language => {
        this.uiLanguage = language.short;
      })
    );

    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
    this.subscription.add(this.userService.getUser().subscribe(user => (this.user = user)));
    this.subscription.add(
      this.listModeSwitchService.saleModeAsObservable().subscribe((saleMode: SaleMode) => {
        this.selectedMode = +saleMode;
      })
    );

    if (
      this.row.type === OrderArticleType.CUSTOM_ITEM_TYPE &&
      this.orderState !== OrderState.DRAFT &&
      (0
        || !this.row.item.code?.length
        || this.row.item.code.indexOf('*') !== -1
        || this.row.formattedTotalConfigurationPurchasePrice === '0.00'
      )
    ) {
      this.invalidRow = true;
    }

    this.subscription.add(
      this.fieldService.getOptionsAsObservable(FieldClass.ORDER).subscribe(groups => {
        const foundFields = groups
          .reduce((accumulator, current) => {
            accumulator.push(
              ...current.values.filter(value => {
                return value.checked;
              })
            );
            return accumulator;
          }, [])
          .map(item => {
            return item.name;
          });

        this.fieldsToShow = foundFields;
      })
    );

    this.subscription.add(
      this.orderArticleRowsFilteringService.getIsFilterEnabledObservable().subscribe((isEnabled) => {
        this.isFilterEnabled = isEnabled;
      })
    );
  }

  isColumnsVisible(targetColumns = []): boolean {
    return targetColumns.some(value => this.fieldsToShow.includes(value) );
  }

  formatDimensions(size: OrderArticlesListRowSizeInterface): string {
    if (!size) {
      return '';
    }

    const { width, depth, height } = size;

    const dimensionsSegments = [
      width ? `W=${width}` : '',
      depth ? `D=${depth}` : '',
      height ? `H=${height}` : ''
    ].filter(segment => segment);

    return dimensionsSegments.join(' ');
  }

  setTotals() {
    this.row.totalVolume = Number(
      (
        this.row.additionalParts?.reduce((acc = 0, item) => {
          return !+item.volume ? acc : acc + +item.volume * item.quantity;
        }, 0) / this.row.quantity
      ).toFixed(3)
    );

    this.row.totalWeight = Number(
      (
        this.row.additionalParts?.reduce((acc = 0, item) => {
          return !+item.weight ? acc : acc + +item.weight * item.quantity;
        }, 0) / this.row.quantity
      ).toFixed(3)
    );
  }

  ngOnChanges(c): void {
    this.setTotals();
    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
  }

  setSelected() {
    this.selected = this.selectedRowsService.isSelectedByRowAndType(this.row, this.row.rowType);
  }

  onToggleRowDetails(row: number) {
    this.openRowDetails = this.openRowDetails !== row ? row : null;
  }

  onToggleSelect() {
    this.onSelect(!this.selected);
  }

  onSelect(selected: boolean) {
    this.selectedRowsService.onSelect(this.row, selected);
  }

  onApplyCustomDiscount(event: OrderArticlesListRowInterface) {
    this.orderArticlesRowsService.setApplyCustomDiscount(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTranslatedDescription(item: OrderArticlesListRowItemInterface, locale: string) {
    if (item.translations.length) {
      const current = item.translations.filter(i => i.locale === locale);
      return current[0]?.content ?? item.translations[0].content;
    }

    return item.shortText || item.longText;
  }

  protected initializeMaterials() {
    if (this.row.type !== OrderArticleType.CUSTOM_ITEM_TYPE || !this.row.materialsAndType?.type) {
      return;
    }

    this.orderArticleMaterialInputService.parseMaterialsString(this.row.materialsAndType.type).subscribe(result => {
      this.rowMaterials = result;
    });
  }

  get isExpiredOldNS(): boolean {
    if (
      !FeatureFlags.oldNonStandardsSupported &&
      this.row.type === OrderArticleType.CUSTOM_ITEM_TYPE &&
      this.userService.isDealer() &&
      this.orderState === OrderState.DRAFT
    ) {
      return true;
    }

    return false;
  }
}
